.navbar {
  height: 60px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 20px;

    .avatar {
      background-color: #3498db;
      color: #fff;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #333;
      text-align: left;

      .username {
        font-weight: bold;
        color: #2c3e50;
      }

      .role {
        font-style: italic;
        color: #7f8c8d;
        font-size: 12px;
      }
    }
  }
}
