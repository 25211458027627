.signle{
  display: flex;
  width: 100%;
  .singleContainer{
    flex:6;
    .top{
      padding: 20px;
      display: flex;
      gap: 20px;

      .left{
        flex:1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        border-radius: 10px;
        padding: 20px;
        position: relative;
        .editButton{
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 15px;
          color:#7451f8;
          background-color:#7551f83b ;
          cursor: pointer;
          border-radius: 0 0 0 5px;
          -webkit-border-radius: 0 0 0 5px;
          -moz-border-radius: 0 0 0 5px;
          -ms-border-radius: 0 0 0 5px;
          -o-border-radius: 0 0 0 5px;
}
        .item{
          display: flex;
          gap: 20px;

          .itemImg{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            object-fit: cover;     
            }
            .detailItem{
              .itemTitle{
                margin-bottom: 10px;
                color: #555;

              }
              .detailItem{
                margin-bottom: 10px;
                font-size: 14px;
                .itemKey{
                  font-weight: bold;
                  color:gray;
                }
                .itemValue{
                  font-weight: 300;
                }
              }
            }

        }
       

      }

      .right{
        flex:2;
        // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        // border-radius: 10px;
        // padding: 20px;
    

      }

    }
  }
  .bottom{
    // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    // border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
  .title{
    font-size: 16px;
    color:lightgray;
    margin-bottom: 20px;
  }
}