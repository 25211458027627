// .table{
//   .status{
//     padding: 5px;
//     border-radius: 5px;

//     &.Approved{
//       color:green;
//       background-color: rgb(0, 128,0, 0.151);
//     }
//     &.Pending{
//       color:goldenrod;
//       background-color: rgb(189,189,0, 0.103);
//     }
  
// }
// }
.css-1q1u3t4-MuiTableRow-root {
  color: inherit;
  display: table-row;
  vertical-align: top;
  outline: 0;
}
.table {
  // margin: 20px; 
  .tableCell {
    // font-weight: bold;
    // padding: 10px;
  }
  
  .status {
    margin: 2px 4x;
    border-radius: 5px;
    
    &.Approved {
      // background-color: green;
      font-weight: 600;
      color: green;
     
      
    }
    
    &.Pending {
      // background-color: orange;
      font-weight: 600;
      color: orange;
      
    }
  }
}
