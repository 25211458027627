
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  
  .css-1q1u3t4-MuiTableRow-root {
    color: inherit;
    display: table-row;
    vertical-align: top !important;
    outline: 0;
  }

  .addButton {
    margin-right: 16px;
    background-color: #2c9294;
  }
  // .table {
  //   margin-top: 20px;
  // }
  
  .tableCell {
    font-weight: normal;
  }
  .status {
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    height: 100%; /* Ensure it takes the full height of the cell */
    &.pending {
      color: orange;
      align-items: center;
      vertical-align: center;
    }
    &.approved {
      color: green;
    }
    &.rejected {
      color: red;
    }
    &.cancelled {
      color: rgb(78, 78, 78);
    }
  }
  