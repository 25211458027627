.sidebar {
  flex: 1;
  border-right: 0.5px solid lightgray;
  min-height: 100vh;
  background-color: white;

  .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #6439ff;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid lightgray;
  }

  .center {
    padding-left: 10px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      .title {
        font-size: 15px;
        font-weight: bold;
        color: #999;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 15px;
        cursor: pointer;
        &.active {
          background-color: #edffff; // Highlight color for active item
          .icon {
            color: #2c9294; // Change icon color for active item
          }
          span {
            color: #2c9294; // Change text color for active item
          }
        }
        &:hover {
          background-color: #edffff;
        }
        .icon {
          font-size: 25px;
          color: #2c9294;
        }
        span {
          font-size: 17px;
          font-weight: bold;
          color: #888;
          margin-left: 10px;
        }
        .badge {
          background-color: #2c9294;
          color: white !important;
          font-size: 17px;
          padding: 2px 6px;
          border-radius: 12px;
          font-size: 12px;
          display: inline-block;
          margin-left: 10px; // Provide space between the text and the badge
        }
      }
    }
  }
}
