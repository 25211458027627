.home {
  display: flex;
}

.homeContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  padding: 20px;
}

/* Add Flexbox for Two Boxes Side by Side */
.boxesContainer {
  display: flex;
  justify-content: space-between; /* Distribute space between the boxes */
  align-items: flex-start; /* Align items to the top */
  gap: 20px; /* Add spacing between the boxes */
}

/* Box for the Monthly Payments Table (60% Width) */
.chartBox {
  flex: 3; /* 60% width */
  background-color: #f9f9f9; /* Optional: Background color */
  padding: 20px;
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
  border: 1px solid #ddd; /* Optional: Border */
}

/* Box for the Financing Summary (40% Width) */
.summaryBox {
  flex: 2; /* 40% width */
  background-color: #f9f9f9; /* Optional: Background color */
  padding: 20px;
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
  border: 1px solid #ddd; /* Optional: Border */
}

.sideBySideContainer {
  display: flex; /* Make the container a flexbox */
  justify-content: space-between; /* Add space between the boxes */
  align-items: flex-start; /* Align items to the top */
  gap: 20px; /* Add spacing between the components */
}

.tableBox {
  flex: 1; /* Equal distribution of space (can adjust as needed) */
  max-width: 49%; /* Limit the width to fit both components side by side */
  background-color: #f9f9f9; /* Optional: Background color */
  padding: 20px;
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
  border: 1px solid #ddd; /* Optional: Border */
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.chartContainer,
.tableContainer {
  margin-bottom: 40px; /* Space between sections */
  background-color: #f9f9f9; /* Optional: Background color for distinction */
  padding: 20px;
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow */
}

.yearSelector {
  margin-bottom: 20px;

  label {
    font-weight: bold;
    margin-right: 10px;
  }

  select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
}

.monthlyPaymentsTable,
.financingSummaryTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th,
  td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:nth-child(odd) {
    background-color: #fff;
  }
}
