.handcash-container {
    max-width: 900px;
    margin: auto;
    padding: 20px;
  
    .customer-info {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
  
      .left-section,
      .right-section {
        flex: 1;
      }
    }
  
    .spaced-input {
      margin-bottom: 10px;
    }
  
    .add-item {
      margin-top: 20px;
  
      .add-item-fields {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 10px;
        align-items: center;
      }
    }
  
    .item-table {
      margin-top: 20px;
  
      table {
        th {
          font-weight: bold;
          background-color: #f5f5f5;
        }
      }
    }
  
    .total-section {
      margin-top: 15px;
      font-weight: bold;
      text-align: right;
    }
  
    .note-section {
      margin-top: 20px;
    }
  
    .received-section {
      margin-top: 20px;
    }
  
    .submit-button {
      margin-top: 20px;
      display: block;
      margin-left: auto;
    }
  }
  