.charts {
    display: flex;
    justify-content: space-around; 
    margin: 0px 0;
  }
  
  .doughnut-chart {
    width: 300px; 
    padding-top: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
   
    background-color: #fff;
  }
  .doughnut {
    margin-top: 50px;
    width: 300px; 
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
   
    background-color: #fff;
  }
  .doughnut-chatbar {
    width: 550px; 
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fff;
  }
  
  .title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .status {
    display: flex;
    justify-content: center;
    // margin-top: 20px;
  }
  
  .status-item {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  
  .status-color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
  }
  