.home{
display: flex;
.homeContainer{

  flex:6;
  .widgets, .charts{
    display: flex;
    padding: 10px;
    // gap: 20px;
  }
  .charts{
    padding: 0px 10px 0px 10px;
    width: 100%;
    }
  .listContainer{

    // padding: 20px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
    // margin: 20px;
    .listTitle{
      font-weight: 500;
      color:gray;
      // margin-bottom: 15px;
    }
  }
}
}